$color: #5382a1;

.App {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin: 12px;
  margin-top: 32px;
}

.title img {
  width: 32px;
}

.toolbar {
  padding: 6px 6px 0 6px;
  .tools {
    display: flex;
    justify-content: space-between;

    .tip {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color;
      font-size: 14px;
      margin-left: 12px;
      cursor: pointer;
      .tip-text {
        margin-right: 2px;
      }
    }

    div.icons {
      display: flex;
      span {
        display: flex;
        margin-right: 12px;
        cursor: pointer;
        padding: 6px;
        border: 1px solid lightgrey;
        border-radius: 6px;
        img {
          width: 22px;
        }
      }
    }
  }
}

.body {
  position: relative;
}

.input {
  display: flex;
}

.input.hide {
  opacity: 0;
}

.buttons {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  position: relative;
  width: 90vw;
}
.run,
.clear {
  cursor: pointer;
}

.buttons button.run {
  font-size: 18px;
  font-weight: bold;
  color: white;
  background: $color;
  padding: 4px 32px;
  margin: 12px;
  border: 1px solid $color;
  border-radius: 4px;
}

.clear {
  position: absolute;
  top: 85px;
  right: 10px;
  border-radius: 50%;
  background: rgba(211, 211, 211, 0.8);
  width: 20px;
  height: 20px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 80%;
}

textarea,
.output {
  font-size: 18px;
  padding: 12px;
  overflow: auto;
}

textarea {
  box-sizing: border-box;
  border-radius: 8px;
  width: 90vw;
  height: 60vh;
}

.output {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 10px 18px 36px 18px;
  .pinyin {
    padding-bottom: 4px;
    select {
      font-size: 100%;
      border: none;
      height: 100%;
      width: 100%;
      color: black;
      cursor: pointer;
      text-align: center;
    }

    @media (max-width: 500px) {
      select {
        appearance: none;
        border-radius: 4px;
        background: #eee;
      }
    }
  }
  .word {
    display: flex;
    flex-direction: column;
    margin-left: 2px;
    margin-bottom: 2px;
    cursor: pointer;
    & > span:first-child {
      flex: 1;
    }
  }
  .loading {
    width: 100%;
  }
}

.tools {
  display: flex;
  justify-content: flex-end;
}

.definition {
  padding: 36px;
  text-align: left;
  .head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 12px;
    border-bottom: 1px solid #ccc;
    overflow: auto;
    .text {
      font-weight: bold;
      margin-right: 24px;
    }

    .pronounciations {
      display: flex;
    }
  }

  .sense {
    display: flex;
    flex-direction: column;
    .pronounciation {
      margin: 6px 0;
    }
    .part-of-speech {
      display: inline;
      background: $color;
      color: white;
      width: fit-content;
      padding: 2px;
      border-radius: 4px;
    }
    .defs {
      padding-inline-start: 20px;
      margin-top: 0;
      .def {
        padding-top: 12px;
        .value {
          padding-bottom: 4px;
        }
        .examples {
          color: #555;
        }
        .classical-examples {
          color: $color;
          margin-top: 6px;
          > div {
            margin-bottom: 6px;
          }
        }
      }
    }
  }

  .languages {
    border-top: 1px solid $color;
    padding-top: 12px;
    margin-top: 6px;
    .language {
      display: flex;
      align-items: center;
      margin-bottom: 6px;
      .type {
        background: $color;
        white-space: nowrap;
        color: white;
        border: 1px solid $color;
        border-radius: 4px;
        padding: 2px;
        font-size: 12px;
        margin-right: 4px;
      }
      .value {
        color: $color;
      }
    }
  }

  .origin {
    border-top: 1px solid $color;
    padding-top: 12px;
    margin-top: 12px;
    .label {
      background: $color;
      white-space: nowrap;
      color: white;
      border: 1px solid $color;
      border-radius: 4px;
      padding: 2px;
      font-size: 12px;
      margin-right: 4px;

    }
    .value {
      color: $color;
    }

  }

  .pronounciation {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 2px 4px;
    cursor: pointer;
    color: black;
    opacity: 1;
    transition: opacity 1s;
    
    & > span {
      margin-right: 2px;
    }

    &:active {
      opacity: 0.5;
      transition: opacity 0s;
    }
  }
}

.disclaimer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
