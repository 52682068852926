.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}


.popup-content {
  background: white;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  transform: scale(0);
  transition: transform 0.1s;
  border-radius: 8px;
  width: 90vw;
  height: 60vh;
  overflow: auto;
}
